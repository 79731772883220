<template>
  <Teleport to="#modals">
    <transition
      enter-active-class="transition duration-150 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-150 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      @after-enter="showContent"
    >
      <div
        v-if="isOpen"
        ref="root"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal_title"
        aria-describedby="modal_subtitle"
        class="fixed inset-0 flex justify-center items-center bg-black/40 backdrop-blur-sm"
        @keydown.shift.tab.exact.prevent="prev"
        @keydown.tab.exact.prevent="next"
      >
        <transition
          enter-active-class="transition transform-gpu duration-150 ease-in-out"
          enter-from-class="opacity-0 scale-90"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="transition transform-gpu duration-150 ease-in-out"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-90"
          @after-enter="focusElement"
          @after-leave="()=> $emit('close')"
        >
          <div
            v-show="content"
            v-off="!isLoading && !persistent && !persistentOnOff && !isEmojiPicker ? close : ''"
            class="relative rounded-3xl "
            :class="[
              centered ? 'text-center' : '',
              modalMaxWidth,
              containContent ? 'object-contain' : 'w-full',
              isFullScreenMobile ? 'm-0 md:m-7 md:my-10' : 'm-7 my-10'
            ]"
          >
            <div
              class="overflow-visible bg-white px-6 md:px-16 py-10 md:py-16 flex flex-col relative"
              :class="isFullScreenMobile ? 'w-full rounded-none md:rounded-3xl h-[100dvh] md:h-auto md:max-h-[calc(100vh-56px)]' : 'max-h-[calc(100vh-56px)] rounded-3xl'"
            >
              <button
                v-if="!persistent"
                class="absolute right-3 top-3 md:right-4 md:top-4 text-brand-copy-light focus:text-black focus:outline-none focus:ring-2 focus:ring-black focus:shadow-brand-accent-light/25 focus:shadow-outer rounded"
                :class="isLoading ? 'cursor-not-allowed' : 'hover:text-black'"
                aria-label="close modal"
                :disabled="isLoading"
                @click.prevent="close()"
              >
                <Icon icon="XIcon" />
              </button>

              <transition
                enter-active-class="transition duration-300 ease-out"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition duration-300 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  v-show="isLoading"
                  class="absolute inset-0 bg-white/75 flex justify-center items-center z-20"
                >
                  <LoadingIcon
                    is-dark
                    size="md"
                    class="animate-spin"
                  />
                </div>
              </transition>

              <transition
                enter-active-class="transition duration-300 ease-out"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition duration-300 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
                mode="out-in"
              >
                <div
                  v-show="content"
                  class="relative max-h-full flex flex-col scrollbar-hide"
                  :class="[centered && 'items-centered', contentOverflow ? 'overflow-visible' : 'overflow-y-auto']"
                >
                  <slot name="upper-content" />

                  <Title
                    v-if="title"
                    id="modal_title"
                    :class="[subtitle ? 'lg:mb-1' : 'mb-2 lg:mb-4']"
                  >
                    {{ title }}
                  </Title>

                  <!-- fallback for when there's no title, SR only -->
                  <h2
                    v-else
                    id="modal_title"
                    class="sr-only"
                  >
                    Modal
                  </h2>

                  <Body
                    v-if="subtitle"
                    size="sm"
                    class="mb-2 lg:mb-4"
                  >
                    {{ subtitle }}
                  </Body>

                  <!-- fallback for when there's no subtitle, SR only -->
                  <h3
                    v-else
                    id="modal_subtitle"
                    class="sr-only"
                  >
                    Subtitle
                  </h3>

                  <Body inline>
                    <slot />
                  </Body>

                  <!-- Desktop Action Buttons -->
                  <div
                    v-if="!hideActionButtons"
                    class="hidden md:block md:space-x-4 mt-8 sticky bottom-0 bg-white/90 p-1.5"
                    :class="[centered ? 'text-center' : 'text-right']"
                  >
                    <GlobalButton
                      priority="secondary-light"
                      :disabled="isLoading"
                      @click="cancel"
                    >
                      {{ cancelLabel }}
                    </GlobalButton>

                    <GlobalButton
                      priority="primary-light"
                      :disabled="isLoading || confirmDisabled"
                      @click="confirm"
                    >
                      {{ confirmLabel }}

                      <LoadingIcon
                        v-if="primaryButtonLoading"
                        class="w-4 h-4 animate-spin"
                      />
                    </GlobalButton>
                  </div>

                  <!-- Mobile Action Buttons -->
                  <div
                    v-if="!hideActionButtons"
                    class="md:hidden space-y-4 mt-8 sticky bottom-0 bg-white/90 p-1.5"
                  >
                    <GlobalButton
                      priority="primary-light"
                      :disabled="isLoading"
                      full-width
                      @click="confirm"
                    >
                      {{ confirmLabel }}

                      <LoadingIcon
                        v-if="primaryButtonLoading"
                        class="w-4 h-4 animate-spin"
                      />
                    </GlobalButton>

                    <GlobalButton
                      priority="secondary-light"
                      :disabled="isLoading"
                      full-width
                      @click="cancel"
                    >
                      {{ cancelLabel }}
                    </GlobalButton>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { watch, ref, onMounted, onUnmounted, type Ref, type PropType, computed } from 'vue'

import GlobalButton from '#components/global/global-button'
import LoadingIcon from '#components/icons/loading-icon.vue'
import Icon from '#components/icon.vue'

import Title from '#components/typography/title'
import Body from '#components/typography/body'

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },

  hideActionButtons: {
    type: Boolean,
    default: false,
  },

  persistent: {
    type: Boolean,
    default: false,
  },

  persistentOnOff: {
    type: Boolean,
    default: false,
  },

  isLoading: {
    type: Boolean,
    default: false,
  },

  cancelLabel: {
    type: String,
    default: 'Cancel',
  },

  confirmLabel: {
    type: String,
    default: 'Continue',
  },

  title: {
    type: String,
    default: '',
  },

  subtitle: {
    type: String,
    default: '',
  },

  centered: {
    type: Boolean,
    default: false,
  },

  contentOverflow: {
    type: Boolean,
    default: false,
  },

  containContent: {
    type: Boolean,
    default: false,
  },

  confirmDisabled: {
    type: Boolean,
    default: false,
  },

  maxWidth: {
    type: String as PropType<MaxWidthType>,
    default: 'xl',
  },

  isFullScreenMobile: {
    type: Boolean,
    default: false,
  },

  isEmojiPicker: {
    type: Boolean,
    default: false,
  },

  primaryButtonLoading: {
    type: Boolean,
    default: false,
  },
})

type MaxWidthType =
| 'xl'
| '2xl'
| '3xl'
| '4xl'

const emit = defineEmits(['close', 'confirm', 'secondary'])

const root: Ref<HTMLElement | null> = ref(null)
const content = ref(false)
// eslint-disable-next-line no-undef
const els: Ref<NodeListOf<HTMLElement> | null> = ref(null)
const i = ref(1)
const prevFocus: Ref<Element | null> = ref(null)

const modalMaxWidth = computed(() => {
  switch (props.maxWidth) {
    default:
    case 'xl':
      return 'max-w-xl'
    case '2xl':
      return 'max-w-2xl'
    case '3xl':
      return 'max-w-3xl'
    case '4xl':
      return 'max-w-4xl'
  }
})

watch(
  () => props.isOpen,
  async (isOpen) => {
    if (isOpen) {
      if (root.value) {
        els.value = root.value.querySelectorAll('input, a[href], button')
      }

      document.body.classList.add('overflow-hidden')
      prevFocus.value = document.activeElement
    } else {
      document.body.classList.remove('overflow-hidden')
      i.value = 1
    }

  }, {
    flush: 'post',
  }
)

const showContent = () => content.value = true
const hideContent = () => content.value = false

/**
 * @todo revisit the `force` hack when the vue-apollo bug has been fixed:
 * https://github.com/vuejs/apollo/issues/1387
 */
const close = (force = false) => {
  if (props.isLoading && !force) {
    return
  }

  document.body.classList.remove('overflow-hidden')
  hideContent()

  if (prevFocus.value instanceof HTMLElement) {
    prevFocus.value.focus({ preventScroll: true })
  }
}

const cancel = () => {
  if (props.cancelLabel !== 'Cancel') {
    emit('secondary')
  } else {
    close()
    emit('close')
  }
}

const confirm = () => {
  emit('confirm')
}

const focusElement = () => {
  if (els.value) {
    // if the element is disabled, skip it and move on to the next focusable
    // element
    if (els.value[i.value] && els.value[i.value].getAttribute('disabled') !== null) {
      next()
    } else if (els.value.length > 1) {
      els.value[i.value].focus({ preventScroll: true })
    } else if (els.value.length === 1) {
      // if there's only one focusable element, disregard "i" value and just
      // focus on it
      els.value[0].focus({ preventScroll: true })
    }
  }
}

const next = () => {
  if (els.value && els.value.length > 0) {
    i.value === els.value.length - 1 ? i.value = 0 : i.value++
    focusElement()
  }
}

const prev = () => {
  if (els.value && els.value.length > 0) {
    i.value === 0 ? i.value = els.value.length - 1 : i.value--
    focusElement()
  }
}

const handleEscape = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    close()
  }
}

onMounted(() => document.addEventListener('keyup', handleEscape))
onUnmounted(() => document.removeEventListener('keyup', handleEscape))
</script>
